<template>
    <div class="card card-height">
        <Teleport to="#add-new-note">
            <button
                @click="addNewNote"
                class="btn btn-primary waves-effect waves-float waves-light"
            >+ Add new
            </button>
        </Teleport>
        <div class="card-body">
            <div class="row">
                <div v-for="(note, index) in tableItems" :key="index" class="col-12 note-section mb-2">
                    <RxNote
                        v-if="['investigation', 'rx'].includes(note.value)"
                        :note="note" :index="index"
                        @openModal="openModal"
                        :medicineNotes="medicineNotes"
                        from="group-template"
                    />
                    <AdmissionNote
                        :note="note" v-else
                        :index="index" 
                        @openModal="openModal"
                        from="group-template"
                    />
                </div>
            </div>
        </div>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>

        <AddNoteModal
            v-if="store.state.isModalOpen"
            :note="singleNote"
        />

        <AddRxNoteModal
            v-if="store.state.isModalOpenTwo"
            :note="singleNote"
        /> 
    </div>
</template>

<script setup>
import { ref } from 'vue';
import RxNote from '@/components/molecule/company/hospital/note/RxNote.vue';
import AddNoteModal from '@/components/molecule/company/hospital/note/AddNoteModal.vue';
import AddRxNoteModal from '@/components/molecule/company/hospital/note/AddRxNoteModal.vue';
import AdmissionNote from '@/components/molecule/company/hospital/note/Note.vue';
import { useStore } from 'vuex';
import { json_string_parse, convertToTitleCase } from '@/services/utils/global';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();

const props = defineProps({
    tableItems: {
        type: Array,
        default: () => []
    },
    prescriptionNotes: {
        type: Object,
        default: () => {}
    }
})

const currentTab = computed(() => route.query.tab || 'rx');
const specialNoteKey = ['rx', 'investigation'];
const noteKeys = computed(() => {
    const keys = Object.keys(props.prescriptionNotes);
    return keys.filter(key => !specialNoteKey.includes(key));
})

const noteTypes = {
    'rx': 'Rx',
    'investigation': 'Investigation',
    'notes': 'Notes'
}

const getNewNote = (value, title) => {
    return {
        name: title,
        value: value,
        isHideAddBtn: false,
        preAddedNotes: getNoteByValue(value),
        selectedNotes: [],
        newNotesByDoctor: [],
        noteMasters: getNoteByValue(value, false)
    }
}

const getNoteByValue = (value, isLimit = true) => {
    const notes = props.prescriptionNotes[value] || [];
    if(!notes.length) return [];

    if(isLimit){
        return notes.slice(0, 10);
    }

    return notes;
}

const medicineNotes = ref([]);
const singleNote = ref(null);

const openModal = ({note, index}) => {
    singleNote.value = null;
    singleNote.value = {
        index: index,
        name: note.name,
        value: note.value,
        selectedNotes: note.selectedNotes,
        preAddedNotes: json_string_parse(note.preAddedNotes),
        noteMasters: note.noteMasters,
        newNotesByDoctor: note.newNotesByDoctor
    }

    if(specialNoteKey.includes(note.value)){
        store.state.isModalOpenTwo = true;
        return;
    }
    
    store.state.isModalOpen = true;
}

const addNewNote = () => {
    if(currentTab.value === 'notes'){
        props.tableItems.unshift(getNewNote('history_note', 'History Note'));
        return;
    }
    const title = noteTypes[currentTab.value];
    props.tableItems.unshift(getNewNote(currentTab.value, title));
}

</script>

<style scoped>
.note-section {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
</style>