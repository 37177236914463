<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="false"
                title="Group Template List"
                :showExtraBtn="false"
            />
        </div>
        <div class="px-2 mb-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onSearch"
                :isHideDate="true"
            >

                <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Search By Template Name</label>
                    <!-- TODO:: Add v-model -->
                </div>

            </DateQuerySetter>
        </div>

        <div class="col-12 px-2">
            <div class="row">
                <div class="col-6">
                    <TemplateNav
                        @onTabChange="onTabChange"
                    />
                </div>
                <div class="col-6 text-right" id="add-new-note"></div>
            </div>
        </div>

        <div class="col-12 px-2 mb-5">
            <ListTable
                :tableItems="tableItems"
                :prescriptionNotes="prescriptionNotes"
                v-if="loadingStatus === 'loaded'"
            />
        </div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/group-template/GroupTemplateTable.vue';
import TemplateNav from '@/components/molecule/company/hospital/group-template/TemplateNav.vue';
import { inject, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import handleHospital from '@/services/modules/hospital'
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import { useStore } from 'vuex';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';

const { fetchPrescriptionNotes } = handleHospital()

const router = useRouter();
const route = useRoute();
const store = useStore();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);
const loadingStatus = ref('none');
const prescriptionNotes = ref({});
const params = ref({
    search_txt: '',
    offset: 20
});
const tableItems = ref([]);

const onTabChange = (tab) => {
    tableItems.value = [];
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onSearch = () => {
    // TODO:: Search
}

async function onPageChange(page) {
    let routeQuery = Object.assign({}, $route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getRegistrationList();
}

function getQuery() {
    let query = `?company_id=${route.params.companyId}`;
    return query;
}

const getPrescriptionNotes = async () => {
    let query = getQuery();
    loadingStatus.value = 'loading'
    await fetchPrescriptionNotes(query).then(res => {
        if (res.status){
            prescriptionNotes.value = res.data;
        }
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loadingStatus.value = 'loaded'
    })
}

const getRegistrationList = async () => {
    let query = getQuery();
    loading.value = true

    // TODO:: Add Registration
}

onMounted(() => {
    Promise.all([
        getPrescriptionNotes()
    ])
})
</script>